import React from "react"

interface Props {
    className?: string,
    id?: string,
}

export const Container: React.FC<Props> = ({ className = "", id, children }) => {
    return (
        <div className={`container ${className}`}>
            {children}
        </div>
    )
}